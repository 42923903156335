import { Routes, Route } from 'react-router-dom';
import React from 'react';

// screens
import Home from './Home';
import About from './About';
// import Alt from './Alt';
import Header from './common/Header';
import Footer from './common/Footer';
import NotFound from './common/NotFound';
import Contact from './Contact';
import { FAQ } from './FAQ';
// import { NPO } from './NPO';
import PrivacyPolicy from './PrivacyPolicty';
import { ContactConfirmed } from './ContactConfirmed';
import { Error } from './Error';

const Routing: React.FunctionComponent = () => {

    return (
        <div>
            <Header/>
            <main>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    {/* <Route path="/alt" element={<Alt />} /> */}
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/contact_confirmed" element={<ContactConfirmed />} />
                    <Route path="/faq" element={<FAQ />} />
                    {/* <Route path="/npo" element={<NPO />} /> */}
                    <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                    <Route path="/error" element={<Error />} />
                    <Route path="" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </main>
            <Footer />
        </div>
    );
}

export default Routing;