import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"

export const ImageAndTitle: React.FunctionComponent<{
    title: string,
    titleSize?:string
    src: string,
    height: number,
    width: number,
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" 
}> = (prop) => {
    return <Grid container
        alignItems="center"
        justifyContent="center"
    >
        <Grid item
            xs={10}>
            <Typography
                sx={{
                    textAlign: "center",
                    fontSize: (theme) => prop.titleSize ? prop.titleSize : theme.typography.h1,
                    color: (theme) => theme.palette.primary.main
                }}
                variant= {prop.variant ? prop.variant : "h2"}
            >
                {prop.title}
            </Typography>
        </Grid>

        <Grid item
            sx={{
                textAlign: "center",
                paddingTop: (theme) => theme.spacing(5),
                paddingBottom: (theme) => theme.spacing(5),
            }}
            xs={10}
        >
            <Box
                component="img"
                sx={{
                    height: { xs: prop.height / 1.5, md: prop.height },
                    width: { xs: prop.width / 1.5, md: prop.width, }
                }}
                alt= {prop.title}
                src={prop.src}
            />
        </Grid>

    </Grid>
}