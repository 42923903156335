import { Container, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useContext } from "react"
import { SectionSeparatorTriangle } from "res/assets/svg/SectionSeparatorTriangle"
import { ThemeContext } from "res/style/theme"
import { WhiteButton } from "./button/WhiteButton"

import calculatorSuitManColor from 'res/assets/common/calculator_suit_man_color.png';
import { FadeIn } from "./FadeIn"

export const LinkToContactUs: React.FunctionComponent = () => {

    // themeを使用可能にする
    const theme = useContext(ThemeContext)

    return <>
        <Container
            maxWidth={false}
            sx={{
                textAlign: "center",
                paddingBottom: (theme) => theme.spacing(7),
                paddingTop: (theme) => theme.spacing(7),
            }}
        >

            <FadeIn>
                <Box
                    component="img"
                    sx={{
                        height: { xs: 100, md: 160 },
                        width: { xs: 100, md: 160, }
                    }}
                    alt="お問合せ"
                    src={calculatorSuitManColor}
                />

                <Typography
                    variant="body1"
                    sx={{
                        paddingTop: (theme) => theme.spacing(6),
                        fontSize: (theme) => theme.typography.h6,
                        fontWeight: "800",
                        color: (theme) => theme.palette.primary.main
                    }}>
                    ご利用料金やサービス内容についてはフォームよりお問い合わせください</Typography>
            </FadeIn>
        </Container>

        <Container
            maxWidth={false}
            sx={{
                backgroundColor: theme.palette.primary.main,
                textAlign: "center",
                paddingBottom: (theme) => theme.spacing(7)
            }}
        >
            <SectionSeparatorTriangle />
            <FadeIn>
            <Box
                textAlign='center'
                sx={{
                    paddingTop: (theme) => theme.spacing(6),
                    paddingBottom: (theme) => theme.spacing(4),
                }}>
                <WhiteButton
                    url="/contact"
                    isXLarge={true}>
                    Contact Us
                </WhiteButton>
            </Box>
            </FadeIn>
        </Container>
    </>
}