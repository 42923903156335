import { Button } from "@mui/material"
import { Size } from "types/Size"

interface ButtonInterface {
    url:string
    size?: Size
    isXLarge?:boolean
  }

export const WhiteButton: React.FC<ButtonInterface> = (prop) => {
    var spacing = 1
    var fontSize = 2
    if(prop.isXLarge == true){
        spacing = 2
        fontSize = 3
    }

    return <Button
    href={prop.url}
    variant="outlined"
    size = {prop.size? prop.size : "large"}
    sx = {{
        fontSize:(theme) => theme.spacing(fontSize),
        paddingTop: (theme) => theme.spacing(spacing),
        paddingBottom: (theme) => theme.spacing(spacing),
        paddingRight: (theme) => theme.spacing(spacing * 2),
        paddingLeft: (theme) => theme.spacing(spacing * 2),
        backgroundColor: (theme) => theme.customPalette.white,
        color: (theme) => theme.palette.primary.main,
        borderColor: (theme) => theme.palette.primary.main,
        borderWidth: 1,
        overflow: "hidden",
        position: "relative",
        zIndex: 1,
        '&:hover': {
            color: (theme) => theme.customPalette.white,
            borderColor: (theme) => theme.customPalette.white,
            borderWidth: 1,
            '&::before': {
                content: '""',
                transformOrigin:"left top",
                transform: 'skewX(-38deg) scale(1, 1)'
            }
        },
        '&::before': {
            backgroundColor: (theme) => theme.palette.primary.main,
            transform: 'skewX(-38deg) scale(0, 1)',
            transition: "transform .3s",
            content: '""',
            transformOrigin:"right top",
            position: "absolute",
            top: 0,
            left:0,
            zIndex: -1,
            width: "120%",
            height: "100%",
        }
    }}
    >{prop.children}</Button>
} 