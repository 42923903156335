import { Button } from "@mui/material"
import { Size } from "types/Size"

interface ButtonInterface {
    url:string
    size?: Size
    onClick?: React.MouseEventHandler<HTMLAnchorElement> 
    disabled?: boolean,
  }

export const MyButton: React.FC<ButtonInterface> = (prop) => {
    return <Button
    onClick={prop.onClick}
    href={prop.url}
    variant="outlined"
    disabled = {prop.disabled}
    size = {prop.size? prop.size : "large"}
    sx = {{
        backgroundColor: (theme) => theme.customPalette.white,
        color: (theme) => theme.palette.primary.main,
        borderColor: (theme) => theme.palette.primary.main,
        borderWidth: 1,
        overflow: "hidden",
        position: "relative",
        '&:hover': {
            color: (theme) => theme.customPalette.white,
            '&::before': {
                content: '""',
                transformOrigin:"left top",
                transform: 'skewX(-38deg) scale(1, 1)'
            }
        },
        '&::before': {
            backgroundColor: (theme) => theme.palette.primary.main,
            transform: 'skewX(-38deg) scale(0, 1)',
            transition: "transform .3s",
            content: '""',
            transformOrigin:"right top",
            position: "absolute",
            top: 0,
            left:0,
            zIndex: -1,
            width: "120%",
            height: "100%",
        }
    }}
    >{prop.children}</Button>
} 