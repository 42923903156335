import { Box, Container, Typography } from "@mui/material"
import NormalGridContainer from "components/common-components/container/NormalGridContainer"
import smartphoneTroubleManColor from 'res/assets/common/smartphone_trouble_man_color.png';
import { MyButton } from "components/common-components/button/MyButton";
import { useEffect } from "react";
import { setTitleAndDescription } from "utils/seoHelper";

export const Error: React.FunctionComponent = () => {

    useEffect(() => {
        setTitleAndDescription("エラー","エラーが発生しました")
    })

    return <>

        <Container
            maxWidth={false}
        >
            <NormalGridContainer
                mdWidth={5}
                xsWidth={11}
                padding={8}>

                <Typography
                    variant="body1"
                    color="primary"
                    sx={{
                        paddingBottom: (theme) => theme.spacing(5)
                    }}>
                    予期せぬエラーが発生しました。<br />お手数ですが、時間をおいて再度お試しください。
                </Typography>

                <Box
                    sx={{
                        paddingBottom: (theme) => theme.spacing(5)
                    }}
                    component="div">
                    <Box
                        component="img"
                        textAlign='center'
                        sx={{
                            width: 100,
                            height: 124
                        }}
                        alt="エラー"
                        src={smartphoneTroubleManColor}
                    />
                </Box>



                <MyButton
                    url="/"
                >
                    ホームへ戻る
                </MyButton>

            </NormalGridContainer>
        </Container>
    </>
}