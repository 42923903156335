import { Container, Divider, List, ListItem, SvgIcon, Typography } from "@mui/material";
import NormalGridContainer from "components/common-components/container/NormalGridContainer";
import Breadcrumb from "../common-components/Breadcrumbs";
import NotListedLocationTwoToneIcon from '@mui/icons-material/NotListedLocationTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import { FadeIn } from "components/common-components/FadeIn";
import { useEffect } from "react";
import { setTitleAndDescription } from "utils/seoHelper";

export const FAQ: React.FunctionComponent = () => {

    const pageName = "よくある質問"

    useEffect(() => {
        setTitleAndDescription(pageName)
    })

    // FAQ
    const faqItems = [

        {
            q: "JETプログラムと民間の違いって何？",
            a: "「雇用・任命責任」が、自治体・民間業者のどちらにあるかというのが最大の違いです。民間業者を活用するメリットとしては、ALTの研修、生活面や緊急時の対応を含め、全て業者にお任せいただける点があります。その他、契約条件や業務内容、コスト面で、豊富なノウハウを生かした柔軟な対応ができるため、民間業者の活用が全国的に増えています。"
        },

        {
            q: "業者選考の前例がないんだけど？",
            a: "業者選考の主な方法として、プロポーザル、入札、見積合せがあります。他市町村での事例をもとに、必要な書式、手続きの違い、メリット・デメリットなどご説明いたしますので、お気軽にご相談ください。"
        },

        {
            q: "契約の形がいくつかあるって聞いたんだけど？",
            a: "民間業者との契約には主に「労働者派遣契約」と「請負契約」の２種類がございます。弊社はどちらも対応可能です。全国の実態、それぞれの特徴と注意点などの情報提供に加え、各種書式のご提供もいたします。ごく少数ですが、上記のほか「コンサルティング契約」という、自治体直接雇用のALTへの管理・サポート業務のみを委託する形もあります。"
        },

        {
            q: "契約期間ってどうなるの？",
            a: "1年又は3年契約が一般的です。契約に関する自治体の規定に従い、任意期間での契約を行います。1年未満の契約や、JETプログラム参加者または直接雇用ALT等の緊急帰国による短期間契約も一度ご相談ください。"
        },

        {
            q: "費用ってどうなるの？安くなる？単価契約とかできる？見積もりが欲しいんだけど？",
            a: "費用についてはお問合せください。配置する場所やタイミングによって管理コストが異なるため、費用は変わります。また、人材関係の業務においては費用の大半が人件費となるため、大幅な値下げは難しいのが正直なところです。年間契約の他、単価契約も可能です。日額、時間、コマ単価なども実績がございます。お見積りのご依頼は、営業担当か、裏表紙の「お問い合わせ先」最寄りの拠点、または大阪本部までお気軽にご連絡ください。"
        },

        {
            q: "費用の内訳は？見積もり以外にかかる費用はある？",
            a: "ALT業務の費用を構成する主な項目は下記のとおりです。【ALT報酬、交通費・住居費補助、各種保険、有給引当金（休業補償）、採用費、研修費、業務管理費、コーディネーター費等】この他、特殊な事情や仕様書外の追加業務のある際、イングリッシュキャンプ、住民向け英会話などの実施には別途費用が必要となります。"
        },


        {
            q: "ALTは本部のある大阪から来るの？",
            a: "ALTは本部・支部で勤務しているわけではありません。ALTは有期雇用の契約社員であり、契約期間中だけ弊社所属となって、自宅⇔勤務先校の間を行き来します。そのため、本部や支部の所在地と、配置先自治体の距離がどれだけ離れてようと全く関係ありません（一部地域では、支部から片道5時間以上かかる自治体へALTを派遣しています）"
        },

        {
            q: "ALTはどこに住むの？",
            a: "就業にあたっては合意の上で転居するALTも多くおります。基本的には就業先へ1時間以内で通勤できるところに住むよう推奨しており、必要に応じて転居サポートも弊社が行います。外国人対応可能な不動産会社と連携しており、ALTの住居は、家具家電付きの民間の物件が大半です。住居の確保が難しい一部地域では教職員住宅をお借りしたり、空き家バンクを活用するケースもございます。"
        },

        {
            q: `ALT決定までにどれぐらいの期間が必要？`,
            a: `業者決定から配置開始までに、1.5ヶ月～2ヶ月の猶予をお願いしています。主に以下3つの理由のためです。\n・無職で待機しているALTがいないこと\n・よりよいALTの選定と、そのマッチングにはある程度時間が必要なこと\n・転居が必要な場合も多いこと\n猶予期間が少ない場合にはALTの質の担保が難しくなります。一方で、タイミングや条件さえ整えばすぐに配置が可能なケースもございます。\n弊社を含め派遣会社各社は、優秀なALTを優先して契約締結を行います。\nこのため、各自治体の業者選考も年々早くなっており、年内や年明け早々に翌年度の業者を決定するケースも増えています。`
        },

        // {
        //     q: "グラッドと他社との違いは？",
        //     a: `外国人登録者の大半は各社共通（重複して派遣会社各社に登録）しており、目に見える部分での違いはあまり多くありません。\nその中で、運営面において、下記2点が他社と大きく異なる点だと弊社は考えています。\n・学校を運営する母体の流れを汲み、先生方と同じ目線での業務運営を方針としていること\n・利益と効率優先の株式会社とは異なり、NPOとしての立場から自治体に寄り添った柔軟な業務運営ができること
        //     `
        // },
    ]

    return <>

        <Breadcrumb pageName={pageName} />

        <Container
            maxWidth={false}
            sx={{
                paddingBottom: (theme) => theme.spacing(6),
                paddingTop: (theme) => theme.spacing(6)
            }}
        >
            <NormalGridContainer
                mdWidth={7}
                xsWidth={12}
                padding={9}>
                <FadeIn>
                    <List>
                        {faqItems.map((item, index) => (
                            <ListItem key={index}
                                sx={{
                                    display: "block",
                                    paddingBottom: (theme) => theme.spacing(5)
                                }}>

                                <Container sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    paddingBottom: (theme) => theme.spacing(3)
                                }}>

                                    <SvgIcon
                                        component={NotListedLocationTwoToneIcon}
                                        color="primary"
                                    />

                                    <Typography
                                        variant="body1"
                                        sx={{
                                            paddingLeft: (theme) => theme.spacing(1),
                                            color: (theme) => theme.palette.primary.main,
                                        }}>
                                        {item.q}
                                    </Typography>

                                </Container>

                                <Container sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingBottom: (theme) => theme.spacing(3)
                                }}>

                                    <SvgIcon
                                        component={QuestionAnswerTwoToneIcon}
                                        color="primary" />

                                    <Typography
                                        variant="body1"
                                        sx={{
                                            paddingLeft: (theme) => theme.spacing(1),
                                            whiteSpace: "pre-line"
                                        }}>
                                        {item.a}
                                    </Typography>
                                </Container>

                                <Divider variant="middle" />
                            </ListItem>
                        ))}
                    </List>
                </FadeIn>
            </NormalGridContainer>
        </Container>
    </>

}