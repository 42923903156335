import { Box, Container, Grid, ListItem, ListItemIcon, ListItemText, Theme, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useWindowDimensions from "utils/useWindowDimensions";
// import { SectionSeparatorCurved } from "res/assets/svg/SectionSeparator";
import { useContext, useEffect } from "react";
import { ThemeContext } from "res/style/theme";
// import { MyButton } from "components/common-components/button/MyButton";
// import getBusinessYear from "utils/getBusinessYear";

import top1 from 'res/assets/top/glad_top2.jpeg';

import detaManColor from 'res/assets/common/deta_man_color.png';
import aisomeSchoolSimple from 'res/assets/common/aisome_school_simple.png';
import handshakeSuitManColor from 'res/assets/common/handshake_suit_man_color.png';
import hnadShakeSuitMan from 'res/assets/common/handshake_suit_man_simple.png';
// import earthColor from 'res/assets/common/earth_color.png';

import { ImageAndTitleAndText } from "components/common-components/container/ImageAndTitleAndText";
import { ImageAndTitle } from "components/common-components/container/ImageAndTitle";
import { LinkToContactUs } from "components/common-components/LinkToContactUs";

import { LinkToFAQ } from "components/common-components/LinkToFAQ";
import { FadeIn } from "components/common-components/FadeIn";
import { setTitleAndDescription } from "utils/seoHelper";
import { SectionSeparatorCurved } from "res/assets/svg/SectionSeparator";

// import FindReplaceRoundedIcon from '@mui/icons-material/FindReplaceRounded';


const Home: React.FunctionComponent = () => {

    // ユーザー画面サイズを取得
    const dimmen = useWindowDimensions();

    // themeを使用可能にする
    const theme = useContext(ThemeContext)

    // 営業年数表示用
    // const [businessYears, setBusinessYears] = useState(0)

    // const ourRef: React.RefObject<HTMLDivElement> = useRef(null)

    useEffect(() => {
        // setBusinessYears(getBusinessYear())

        const pageTitle = "ALT 外国人の派遣・紹介はフロントへ"
        const pageDescription = "株式会社フロントの会社情報と事業内容についてご案内致します。"
        setTitleAndDescription(pageTitle, pageDescription)
    },
        // 空の配列をセット → 初回のみ副作用関数を実行
        []);

    function getOpacity(): number {
        if (theme.breakpoints.values.md > dimmen.width) {
            return 1 - (dimmen.width / theme.breakpoints.values.md) * 0.65
        } else {
            return 0
        }
    }

    return (
        <>
            <Container
                sx={{
                    bgcolor: (theme) => theme.palette.secondary.light,
                    paddingTop: (theme) => theme.spacing(5),
                    paddingBottom: (theme) => theme.spacing(5),
                    backgroundImage: `url(${top1})`,
                    backgroundPosition: 'right top',
                    backgroundPositionX: "100%",
                    backgroundPositionY: "0%",
                    position: "relative",
                    backgroundSize: "cover",
                    WebkitBackgroundSize: "cover",
                    // Parallax scrolling effect
                    backgroundAttachment: {
                        xs: "none", // スマホではOFFにしないとbackgroundPositionがバグる
                        md: "fixed",
                    },
                    minHeight: 1,
                    minWidth: 1,
                    '&:after': {
                        content: '""',
                        backgroundColor: `rgba(255,255,255,${getOpacity()})`,
                        position: "absolute",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        Zindex: -9999,
                    },
                    height: (theme) => getImageHeight(theme, dimmen.width),
                }}
                maxWidth={false} >

                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="xl"
                    xs={12}
                    sx={{
                        height: (theme) => getImageHeight(theme, dimmen.width),
                        padding: (theme) => theme.spacing(3),
                    }}>

                    <Grid item>

                        <FadeIn>
                            <Typography
                                variant="h1"
                                sx={{
                                    color: (theme) => theme.palette.primary.main,
                                    paddingTop: (theme) => theme.spacing(2),
                                    paddingBottom: (theme) => theme.spacing(2),
                                    textAlign: "center",
                                    fontWeight: "900",
                                    position: "relative",
                                    zIndex: 10,
                                    fontSize: {
                                        md: `2.4em!important`,
                                        xs: `1.6em!important`
                                    }
                                }}>
                                外国人講師をお探しですか ?
                            </Typography>

                            <Typography
                                variant="h6"
                                sx={{
                                    color: (theme) => theme.palette.primary.main,
                                    paddingTop: (theme) => theme.spacing(2),
                                    paddingBottom: (theme) => theme.spacing(2),
                                    fontWeight: "550",
                                    textAlign: "center",
                                    position: "relative",
                                    zIndex: 10,
                                    fontSize: {
                                        md: `1.2em!important`,
                                        xs: `1em!important`
                                    }
                                }}>
                                ALTや外国人の派遣・紹介はフロントへ
                            </Typography>
                        </FadeIn>
                    </Grid>

                    <Grid
                        item
                        md={6}
                        sx={{
                            display: { xs: "none", md: "block" }
                        }}>
                    </Grid>
                </Grid>
            </Container>

            {/* <Grid container
                alignItems="center"
                justifyContent="center"
                sx={{
                    backgroundColor: (theme) => theme.palette.secondary.light,
                    paddingTop: (theme) => theme.spacing(10),
                }}
            >
                <Grid container
                    alignItems="center"
                    justifyContent="center"
                    xs={10}
                    md={4}
                    sx={{
                        paddingBottom: (theme) => theme.spacing(3),
                    }}>
                    <FadeIn>
                        <Box
                            ref={ourRef}
                            component="img"
                            sx={{
                                height: { xs: 130, md: 250 },
                                width: { xs: 130, md: 250 }
                            }}
                            alt="派遣実績"
                            src={guideSuitManColor}
                        />
                    </FadeIn>
                </Grid>

                <Grid container
                    alignItems="center"
                    justifyContent="center"
                    xs={11}
                    md={4}
                    sx={{
                        paddingBottom: (theme) => theme.spacing(5),
                        paddingTop: (theme) => theme.spacing(5),
                    }}
                >
                    <Grid item>
                        <FadeIn>
                            <Card
                                sx={{
                                    padding: (theme) => theme.spacing(2)
                                }}>
                                <CardContent>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            fontSize: (theme) => theme.typography.h1,
                                            paddingBottom: (theme) => theme.spacing(3),
                                            color: (theme) => theme.palette.primary.main,
                                            textAlign: "center"
                                        }}>
                                        派遣先
                                    </Typography>

                                    <List dense={false}>
                                        <CreatArchiveList
                                            primary="中・高等学校"
                                            secondary="英語指導経験のある優秀なALTの派遣が可能です。" />

                                        <CreatArchiveList
                                            primary="幼稚園"
                                            secondary="ここに何か書く?" />

                                        <CreatArchiveList
                                            primary="インターナショナルスクール"
                                            secondary="ここに何か書く?" />

                                        <CreatArchiveList
                                            primary="私塾"
                                            secondary="ここに何か書く?" />
                                    </List>
                                </CardContent>
                            </Card>
                        </FadeIn>
                    </Grid>
                </Grid>
                <SectionSeparatorCurved color={theme.customPalette.lightBlue} />
            </Grid> */}

            <Container
                maxWidth={false}
                sx={{
                    textAlign: "center",
                    paddingBottom: (theme) => theme.spacing(7),
                    paddingTop: (theme) => theme.spacing(13),
                }}>
                  <Box
                    component="img"
                    sx={{
                        height: { xs: 100, md: 110 },
                        width: { xs: 100, md: 110, },
                    }}
                    alt="お問合せ"
                    src={hnadShakeSuitMan}
                />
                <Grid container
                    alignItems="center"
                    justifyContent="center">
                    <Grid item
                        md={7}
                        xs={10}>
                        <FadeIn>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    paddingTop: (theme) => theme.spacing(3),
                                    paddingBottom: (theme) => theme.spacing(3),
                                    color: (theme) => theme.palette.primary.main
                                }}
                                variant="h2"
                            >
                                優秀な外国人を派遣します！
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    paddingBottom: (theme) => theme.spacing(3),
                                }}
                                variant="body1"
                            >
                                英語指導経験のある優秀なALTや日本での生活経験のある外国人の派遣が可能です。<br />
                                経験年数、校種、学級人数、T1/T2、性格といった要素から要望により合致した人材を選定し派遣致します。
                            </Typography>
                        </FadeIn>
                    </Grid>
                    <Grid xs={12} />
                </Grid>
            </Container>

            <SectionSeparatorCurved color={theme.customPalette.lightBlue} />
            <Container
                maxWidth={false}
                sx={{
                    backgroundColor: (theme) => theme.customPalette.lightBlue,
                    paddingBottom: (theme) => theme.spacing(5),
                    paddingTop: (theme) => theme.spacing(5),
                }}>

                <Grid container
                    alignItems="center"
                    justifyContent="center"
                >
                    <FadeIn >
                        <ImageAndTitle
                            title="事業内容"
                            height={250}
                            width={250}
                            src={detaManColor}
                        />
                    </FadeIn>

                    <FadeIn>
                        <ImageAndTitleAndText
                            title="ALT派遣事業"
                            titleLevel="h2"
                            body="質の高く安定した外国語指導助手の派遣・配置業務をはじめ、地域のニーズに寄り添ったサポート、そして外国語教育や国際化教育に関するトータルパッケージをご提供いたします。"
                            src={aisomeSchoolSimple}
                            height={150}
                            width={150}
                        />
                    </FadeIn>

                    <FadeIn>
                        <ImageAndTitleAndText
                            title="外国人有料職業紹介"
                            titleLevel="h2"
                            body="外国人の正社員・派遣社員紹介事業として、国内外のネットワークを活かし、
                        外国人の採用に苦戦を強いられている企業様や採用担当者様の一助となれるサービスをご提供いたします。"
                            isPictureOnright={false}
                            src={handshakeSuitManColor}
                            height={150}
                            width={150}
                        />
                    </FadeIn>

                    {/* <FadeIn>
                        <ImageAndTitleAndText
                            title="NPO活動"
                            titleLevel="h2"
                            body="急速に進むグローバル化・ボーダレス化に対応できる社会をめざして、様々な取り組みが全国の自治体で行われています。"
                            src={earthColor}
                            height={150}
                            width={150}
                        />
                    </FadeIn> */}

                </Grid>
            </Container>
            <Container
                maxWidth={false}
                sx={{
                    paddingBottom: (theme) => theme.spacing(7),
                    paddingTop: (theme) => theme.spacing(7),
                }}>
                <Grid container
                    alignItems="center"
                    justifyContent="center">
                    <Grid item
                        md={7}
                        xs={10}>
                        <FadeIn>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    paddingBottom: (theme) => theme.spacing(3),
                                    color: (theme) => theme.palette.primary.main
                                }}
                                variant="h3"
                            >
                                外国人の採用・登録について
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    paddingBottom: (theme) => theme.spacing(3),
                                }}
                                variant="body1"
                            >
                                世界中からの応募者を複数段の絞り込みを行い、優秀な外国人のみ採用・登録しています。<br />
                                特に以下の条件は注意していますので要望に合わせた外国人をご紹介可能です。
                            </Typography>
                        </FadeIn>
                    </Grid>

                    <Grid xs={12} />

                    <Grid item
                        md={5}
                        xs={9}
                        sx={{
                            paddingTop: (theme) => theme.spacing(2),
                            paddingBottom: (theme) => theme.spacing(2),
                        }} >

                        <FadeIn>
                            <CreateReqruimentList text="英語指導経験" />
                            <CreateReqruimentList text="日本語レベル" />
                            <CreateReqruimentList text="日本での経験" />
                            {/* <CreateReqruimentList text="国籍の指定" /> */}
                            {/* <Box
                                textAlign='center'
                                sx={{
                                    paddingTop: (theme) => theme.spacing(4),
                                    paddingBottom: (theme) => theme.spacing(4),
                                }}>
                                <MyButton url={"/alt"}>
                                    詳しく見る
                                </MyButton>
                            </Box> */}
                        </FadeIn>
                    </Grid>
                </Grid>
            </Container>

            {/* よくある質問 */}
            <LinkToFAQ />

            {/* お問合せ */}
            <LinkToContactUs />
        </>
    );
}

// 派遣実績
// const CreatArchiveList: React.FunctionComponent<{ primary: string, secondary: string }> = (prop) => {
//     return <ListItem>
//         <ListItemText
//             primary={
//                 <Typography
//                     variant="h3"
//                     sx={{
//                         color: (theme) => theme.palette.text.primary,
//                         textDecorationColor: (theme) => theme.palette.primary.main,
//                         textUnderlineOffset: (theme) => theme.spacing(1),
//                         textDecoration: "underline",
//                         paddingBottom: (theme) => theme.spacing(3),
//                     }}>{prop.primary}</Typography>
//             }
//             secondary={
//                 <Typography
//                     variant="body1"
//                     sx={{
//                         color: (theme) => theme.palette.grey[600],
//                     }}>{prop.secondary}</Typography>
//             }
//         />
//     </ListItem>
// }

// 外人要件
const CreateReqruimentList: React.FunctionComponent<{ text: string }> = (prop) => {
    return <ListItem>
        <ListItemIcon>
            <CheckCircleIcon
                sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontSize: (theme) => theme.spacing(4)
                }} />
        </ListItemIcon>
        <ListItemText primary={
            <Typography
                variant="body1"
                sx={{
                    fontWeight: "800",
                    color: (theme) => theme.palette.grey[600]
                }}>
                {prop.text}</Typography>
        } />
    </ListItem>
}

function getImageHeight(theme: Theme, width: number): number {

    const maxHeight = parseInt(theme.spacing(90))
    const heightForSmallerScreen = maxHeight * (width / (theme.breakpoints.values.xl * 0.4))

    if (width > theme.breakpoints.values.xl) {
        return maxHeight
    } else {
        if (heightForSmallerScreen > maxHeight) {
            return maxHeight
        } else {
            return heightForSmallerScreen
        }
    }
}

export default Home;


