import { Fade } from "@mui/material"
import { useInView } from "react-intersection-observer";

export const FadeIn: React.FunctionComponent<{timeout?:number}> = (prop) => {

    const { ref, inView } = useInView({
        // オプション
        rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
        triggerOnce: true, // 最初の一度だけ実行
    });

    return <Fade
        timeout={prop.timeout ? prop.timeout : 1000}
        in={inView}
    >
        {<div ref={ref}>{prop.children}</div>}
    </Fade>
}