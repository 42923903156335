import { Breadcrumbs, Link, Typography } from "@mui/material";

const Breadcrumb: React.FunctionComponent<{ pageName: string }> = (prop) => {

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
                backgroundColor: (theme) => theme.customPalette.superLightBlue,
                paddingTop: (theme) => theme.spacing(2),
                paddingBottom: (theme) => theme.spacing(2),
                paddingRight: (theme) => theme.spacing(4),
                paddingLeft: (theme) => theme.spacing(6)
            }}
        >
            <Link
                underline="hover"
                color="inherit"
                href="/"
                sx={{
                    fontColor: (theme) => theme.customPalette.gray
                }}>
                ホーム
            </Link>
            <Typography
                variant="h1"
                sx={{
                    fontSize: `1em!important`,
                    fontColor: (theme) => theme.customPalette.gray
                }}
            >{prop.pageName}</Typography>
        </Breadcrumbs>
    );
}

export default Breadcrumb;