import CircularProgress from '@mui/material/CircularProgress';

export const FixedCircularProgress: React.FunctionComponent<{ isVisible: boolean }> = (prop) => {
    return <CircularProgress
        sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translateX(-50%),translateY(-50%)",
            zIndex: 9999,
            display: prop.isVisible ? "block" : "none"
    }}
    />
}