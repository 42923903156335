import { useContext } from "react"
import { ThemeContext } from "res/style/theme"


type SectionSeparatorInterface = {
    isUpSide?: boolean,
    isBig?: boolean,
    color?: string
}

export const SectionSeparatorCurved: React.FunctionComponent<SectionSeparatorInterface> = (props: SectionSeparatorInterface) => {

    // themeを使用可能にする
    const theme = useContext(ThemeContext)

    // PATHタグの基本
    // M → 開始座標
    // 例)
    // M0,0 → 左上が開始地点
    // L,l 直線を引く (垂直、並行ではない)
    // 例) 
    // L10,10 → 右に10下に10の地点に向かって直線を引く
    // H,h → 水平線を引く
    // V,v → 垂直線を引く
    // Z,z → 直近のMまで戻り、描画終了
    // C,c,S,s,Q,q,T,t → 曲線を引く 端点と制御点とで曲線を指定する
    return (
        <svg
            display="block"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 259">
            <path
                fill={props.color ? props.color : theme.customPalette.lightBlue}
                fill-opacity="1"
                d="M0,32L48,26.7C96,21,192,11,288,58.7C384,107,480,213,576,218.7C672,224,768,128,864,106.7C960,85,1056,139,1152,165.3C1248,192,1344,192,1392,192L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>


    )
}