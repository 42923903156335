import { AppBar, Link, List, ListItem, ListItemText, ListItemButton, SvgIcon, SwipeableDrawer, Toolbar, Box } from "@mui/material";
import { headerLinks } from "const/headerLinks";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";
import useWindowDimensions from "utils/useWindowDimensions";
import { useContext } from "react";
import { ThemeContext } from "res/style/theme";
import logo from 'res/assets/logos/Front_86.jpg';

const Header: React.FunctionComponent = () => {

    // Header上下のpadding
    const verticalBuffer = 2
    // 各アイテムの左右padding
    const horizontiallBuffer = 4

    // ユーザー画面サイズを取得
    const dimmen = useWindowDimensions();

    // themeを使用可能にする
    const theme = useContext(ThemeContext)

    const handleHamburgerIconClick = () => {
        setState(true)
    }

    const [state, setState] = useState(false);

    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setState(open);
            };
    return (
        <>
            <AppBar
                sx={{
                    bgcolor: (theme) => theme.customPalette.white,
                    paddingTop: (theme) => theme.spacing(verticalBuffer),
                    paddingLeft: (theme) => theme.spacing(verticalBuffer),
                    paddingBottom: (theme) => theme.spacing(verticalBuffer),
                    boxShadow: 0
                }}
                position="static">

                <Toolbar
                    sx={{
                        justifyContent: "space-between"
                    }}>
                    <>
                        <Link
                        href="/">
                            <Box
                                alignItems="center"
                                justifyContent="center"
                                component="img"
                                sx={{
                                    Height: 70,
                                    width: 70,
                                    maxHeight: 1,
                                    maxWidth: 1,
                                    textAlign: "right"
                                }}
                                alt="ロゴ"
                                src={logo}>
                            </Box>
                        </Link>
                    </>

                    <div style={{
                        display: "flex"
                    }}>
                        <SvgIcon
                            fontSize="large"
                            onClick={handleHamburgerIconClick}
                            component={MenuIcon}
                            color="primary"
                            sx={{
                                display: { xs: "block", md: "none" }
                            }}
                        />
                        <SwipeableDrawer
                            anchor="right"
                            open={state && dimmen.width < theme.breakpoints.values.md}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}>
                            <nav>
                                <List
                                    sx={{
                                        paddingTop: (theme) => theme.spacing(5),
                                        paddingBottom: (theme) => theme.spacing(1),

                                    }}>
                                    {headerLinks.map((item, _) => (
                                        <ListItem>
                                            <ListItemButton>
                                                <ListItemText
                                                    primary={
                                                        <Link
                                                            underline="none"
                                                            href={item.url}
                                                            sx={{
                                                                textAlign: "center",
                                                                paddingRight: (theme) => theme.spacing(5),
                                                                paddingLeft: (theme) => theme.spacing(5),
                                                                paddingTop: (theme) => theme.spacing(1),
                                                                paddingBottom: (theme) => theme.spacing(1)
                                                            }}>
                                                            {item.text}
                                                        </Link>
                                                    }
                                                    sx={{
                                                        display: "inline-block",
                                                        textAlign: "center"
                                                    }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </nav>
                        </SwipeableDrawer>
                        <nav style={{
                            display: "flex"
                        }}>
                            {headerLinks.map((item, index, array) => {
                                if (item.text == "ENGLISH") {
                                    return <Link
                                        underline="none"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={item.url}
                                        sx={{
                                            marginLeft: (theme) => theme.spacing(horizontiallBuffer),
                                            display: { xs: "none", md: "block" }
                                        }}>{item.text}</Link>
                                }

                                if (index == array.length - 1) {
                                    return <Link
                                        underline="none"
                                        href={item.url}
                                        sx={{
                                            marginLeft: (theme) => theme.spacing(horizontiallBuffer),
                                            marginRight: (theme) => theme.spacing(horizontiallBuffer * 2),
                                            display: { xs: "none", md: "block" }
                                        }}>{item.text}</Link>
                                } else {
                                    return <Link
                                        underline="none"
                                        href={item.url}
                                        sx={{
                                            marginLeft: (theme) => theme.spacing(horizontiallBuffer),
                                            display: { xs: "none", md: "block" }
                                        }}>{item.text}</Link>
                                }
                            })}
                        </nav>
                    </div>
                </Toolbar>
            </AppBar>
        </>
    );
}
export default Header;