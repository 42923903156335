import { Grid } from "@mui/material";

const NormalGridContainer: React.FC<{
    mdWidth?: number,
    xsWidth?: number,
    padding?: number,
}> = (prop) => {
    var mdWidth = 6
    if (prop.mdWidth != null) {
        mdWidth = prop.mdWidth
    }

    var xsWidth = 10
    if (prop.xsWidth != null) {
        xsWidth = prop.xsWidth
    }

    var padding = 2
    if (prop.padding != null) {
        padding = prop.padding
    }
    return <Grid container
        alignItems="center"
        justifyContent="center"
    >
        <Grid item
            sx={{
                textAlign: "center",
                paddingBottom: (theme) => theme.spacing(padding),
                paddingTop: (theme) => theme.spacing(padding)
            }}
            xs={xsWidth}
            md={mdWidth}
        >
            {prop.children}
        </Grid>
    </Grid>
}

export default NormalGridContainer;