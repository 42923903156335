import { styled } from '@mui/system';
// import { useEffect, useState } from 'react';
// import getBusinessYear from "utils/getBusinessYear";

// muiのスタイリング手法と違うので違和感
// themeも使えないので直で指定....
const CompanyDetailTableStyle = styled('div')`
  table {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
  }

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 15px;
  }

  th {
    background-color: #eff9ff66;
  }
`;

export default function CompanyDetailTable() {

    // 営業年数表示用
    // const [businessYears,setBusinessYears] = useState(0)

    // useEffect(() => {     
    //     setBusinessYears(getBusinessYear())
    // },
    // 空の配列をセット → 初回のみ副作用関数を実行
    // []);
    

    return (
        <CompanyDetailTableStyle sx={{ maxWidth: '100%', width: 800 }}>
            <table >

                <tbody>
                    <tr>
                        <th>法人名</th>
                        <td>株式会社フロント　

                        </td>
                    </tr>
                    <tr>
                        <th>代表者</th>
                        <td>和田守弘</td>
                    </tr>
                    <tr>
                        <th>資本金</th>
                        <td>20,000,000円</td>
                    </tr>
                    {/* <tr>
                        <th>営業年数</th>
                        <td>{businessYears}年(平成23年9月21日設立)</td>
                    </tr> */}
                    <tr>
                        <th>所在地</th>
                        <td>〒530-0035 大阪市北区同心2-2-7</td>
                    </tr>
                    <tr>
                        <th>事業内容</th>
                        <td>
                            <dl>
                                
                                <dd>①　労働者派遣事業
                                </dd>
                                <dd>②　語学指導請負業務
                                </dd>
                                <dd>③　有料職業紹介事業</dd>
                                <dd>④　研修会、研究会、講演会、展示会等の企画、運営及び管理</dd>
                                <dd>⑤　教育に関する調査、研究、情報提供及びコンサルティング</dd>
                                <dd>⑥　学習教材、図書の出版及び販売</dd>
                                <dd>⑦　学校の経営及び委託並びに受託運営</dd>
                                <dd>⑧　国内及び外国の文化・教育交流の企画斡旋事業</dd>
                                <dd>⑨　イングリッシュキャンプ・カルチャーフェア等の異文化体験事業</dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <th>事業許可</th>
                        <td>
                            <ul>
                                <li>労働者派遣事業許可：　派27-305018</li>
                                <li>有料職業紹介事業許可： 27-ユ-303814</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </CompanyDetailTableStyle>
    );
}