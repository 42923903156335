// import { Container, Typography } from "@mui/material";
// import { Box } from "@mui/system";
import Breadcrumb from "../common-components/Breadcrumbs";

// import event02Color from 'res/assets/common/event_02_color.png';
// import visionOPfCompany from 'res/assets/common/vision_of_company.png';
// import bgLOragne from 'res/assets/common/bg_orange_with_cube.png';
import aisomeBuildingSimple from 'res/assets/common/aisome_building_simple.png';
// import ceo from 'res/assets/common/ceo.png';

import { ImageAndTitle } from "components/common-components/container/ImageAndTitle";
import NormalGridContainer from "components/common-components/container/NormalGridContainer";
// import { Box } from "@mui/system";
// import { Parallax } from "react-parallax";
import CompanyDetailTable from "../common-components/CompanyDetailTable";
import { LinkToContactUs } from "components/common-components/LinkToContactUs";
import { FadeIn } from "components/common-components/FadeIn";
import { useEffect } from "react";
import { setTitleAndDescription } from "utils/seoHelper";

const About: React.FunctionComponent = () => {

    const pageName = "会社概要"

    useEffect(() => {
        setTitleAndDescription(pageName)
    })

    return (
        <>
            <Breadcrumb pageName={pageName} />

            {/* <Container
                maxWidth={false}
                sx={{
                    paddingBottom: (theme) => theme.spacing(8),
                    paddingTop: (theme) => theme.spacing(8),
                }}>

                <FadeIn>
                    <ImageAndTitle
                        title="企業理念"
                        height={160}
                        width={160}
                        src={event02Color}
                    />
                </FadeIn>

                <NormalGridContainer>
                    <FadeIn>
                        <Typography
                            sx={{
                                paddingBottom: (theme) => theme.spacing(8),
                                textAlign: "left"
                            }}
                            variant="body1">
                            弊社は単なるALTの派遣会社ではありません。<br />
                            「本当に英語を話せる子どもたち」を育てる強い思いと責任感を持って業務に取り組んでいます。<br />
                            これからの日本に求められるのは、世界中の人々と対等に渡り合っていくコミュニケーション力、語学力、適応力、そして人間力を備えている人物です。<br />
                            そうした子どもたちの育成のため、以下のように、弊社の理念・方向性を定めています。
                        </Typography>
                    </FadeIn>
                </NormalGridContainer>

                <NormalGridContainer
                    xsWidth={12}>

                    <FadeIn>
                        <Box
                            alignItems="center"
                            justifyContent="center"
                            component="img"
                            sx={{
                                Height: 300,
                                width: 600,
                                maxHeight: 1,
                                maxWidth: 1,
                                textAlign: "right"
                            }}
                            alt="目指すべき姿"
                            src={visionOPfCompany}
                        />
                    </FadeIn>

                </NormalGridContainer>

            </Container> */}

            {/* <Parallax
                strength={500}
                bgImage={bgLOragne}
            > */}

                <NormalGridContainer
                    xsWidth={11}
                    padding={10}>

                    <FadeIn>
                        <ImageAndTitle
                            title="会社概要"
                            height={160}
                            width={160}
                            src={aisomeBuildingSimple}
                        />
                        <CompanyDetailTable />
                    </FadeIn>
                </NormalGridContainer>
            {/* </Parallax> */}

            {/* <NormalGridContainer
                xsWidth={11}
                padding={10}>
                <FadeIn>
                    <ImageAndTitle
                        title="代表挨拶"
                        height={250}
                        width={250}
                        src={ceo}
                    />

                    <Typography
                        variant="h6"
                    >
                        NPOグローバル教育推進機構理事<br />中園　潔
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: "left",
                            paddingTop: (theme) => theme.spacing(3),
                            paddingBottom: (theme) => theme.spacing(3),
                        }}>
                        「人生は楽しい」これが私がいつも心に刻んでいる言葉です。<br />
                        実際人生は8割苦しく、2割くらいがちょうど楽しいのではないかと思っております。つまりだいたいは苦しいのです。<br />
                        しかし、この苦しみを一緒に乗り越えてくれる人がいたり、乗り越えた後の達成感を味わうことができたり、実は人生は生きてるだけで楽しいイベントが満載なのです。<br />
                        自分の子どもたちに対してだけでなく、教育業界のひとりとして出会った子どもたちに対して、「人生は楽しい」ということを伝える旅に出てもう数十年が経ちました。<br />
                        現在この法人の事業を通して、国際社会の一員として、世界中の人たちと出会う環境の中で「人生が楽しい」という意味を体感し、一緒に過ごす人たちと涙を流し、喜びあう、自分の足で歩いていく人生を送ってもらいたいと思っております。<br />
                        この「人生は楽しい」を具現化できる活動を常に行っていきたいと思っております。
                    </Typography>
                </FadeIn>
            </NormalGridContainer> */}

            {/* お問合せ */}
            <LinkToContactUs />
        </>
    );
}

export default About;