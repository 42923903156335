import { CssBaseline, responsiveFontSizes, ThemeProvider } from "@mui/material";
import Routing from "components/screens-components/Routing";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { myTheme } from "res/style/theme";

function App() {
  return (
    <ThemeProvider theme={responsiveFontSizes(myTheme)} >
      {/* mui作のデフォルトcss margin打ち消し等 */}
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Routing />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

