import { Box, Container, Stack, TextField, Typography } from "@mui/material";
import { MyButton } from "components/common-components/button/MyButton";
import NormalGridContainer from "components/common-components/container/NormalGridContainer";
import Breadcrumb from "../common-components/Breadcrumbs";
import { SubmitHandler, useForm } from 'react-hook-form'

import aisomeMovingTrackColor from 'res/assets/common/aisome_moving_track_color.png';
import { ImageAndTitle } from "components/common-components/container/ImageAndTitle";

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useContext, useEffect, useState } from "react";
import { WhiteButton } from "components/common-components/button/WhiteButton";
import { ThemeContext } from "res/style/theme";
import axios from "axios";
import { FixedCircularProgress } from "components/common-components/FixedCircularProgress";
import { useNavigate } from "react-router-dom";
import { FadeIn } from "components/common-components/FadeIn";
// import emailjs from '@emailjs/browser';
import { setTitleAndDescription } from "utils/seoHelper";

const Contact: React.FunctionComponent = () => {

    // themeを使用可能にする
    const theme = useContext(ThemeContext)

    const navigate = useNavigate();

    const pageName = "お問い合わせ"

    useEffect(() => {
        setTitleAndDescription(pageName)
    })

    // メアド
    const [email, setEmail] = useState('');
    // 本文
    const [content, setContent] = useState('');
    // 件名 
    const [title, setTitle] = useState('');
    // 名前 
    const [name, setName] = useState('');
    // ローディング表示有無
    const [isVisible, setLoadingVisivility] = useState(false);

    // フォームの型
    interface FormInputInterface {
        title: string // 名前
        email: string // メールアドレス
        body: string // 問い合わせ本文
        name: string // お名前
    }

    // バリデーションルール
    const schema = yup.object({
        email: yup
            .string()
            .required('メールアドレスは必須項目です')
            .email('正しいメールアドレス入力してください'),
        title: yup.string()
            .required('件名は必須項目です'),
        name: yup.string()
            .required('お名前は必須項目です'),
        body: yup
            .string()
            .required('お問い合わせ内容は必須項目です')
            .min(10, '10文字以上入力してください')
    })

    const { register, handleSubmit, formState: { errors } } = useForm<FormInputInterface>({
        resolver: yupResolver(schema),
    })

    // メアド変更検知リスナー
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value)
    }

    // 本文変更検知リスナー
    const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContent(e.currentTarget.value)
    }

    // 件名変更検知リスナー
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.currentTarget.value)
    }

    // 件名変更検知リスナー
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }

    // フォーム送信時の処理
    // スパム対策は暫定的に行わない 
    // 理由:  reCAPTCHA の実装にも結局サーバーが必要ということが判明したので
    // Herokeへと移行後にサーバーを実装するので、その際にスパム対策も行う
    const onSubmit: SubmitHandler<FormInputInterface> = async () => {

        setLoadingVisivility(true)

        const param = {
            name: name,
            title: title,
            email: email,
            body: content,
        };

        try {
            await axios.post(process.env.REACT_APP_SERVER_DOMAIN + "server/index.php", param).then((response) => {
                const isSuccess = response.data["isSuccess"]
                if(isSuccess){
                    setLoadingVisivility(false)
                    navigate('/contact_confirmed');
                }else{
                    setLoadingVisivility(false)
                    navigate('/error');
                }
            });
        } catch { 
            setLoadingVisivility(false)
            navigate('/error');
        }
    }

    return (
        <>
            <FixedCircularProgress isVisible={isVisible} />
            <Breadcrumb pageName={pageName} />
            <Container
                maxWidth={false}
                sx={{
                    paddingTop: (theme) => theme.spacing(8),
                    paddingBottom: (theme) => theme.spacing(4)
                }}>
                <FadeIn>
                    <ImageAndTitle
                        title="お問い合わせ"
                        height={130}
                        width={130}
                        src={aisomeMovingTrackColor}
                    />
                </FadeIn>
            </Container>

            <Container
                maxWidth={false}
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.main
                }}
            >
                <NormalGridContainer
                    mdWidth={5}
                    xsWidth={10}
                    padding={6}>

                    <Container
                        maxWidth={false}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            textAlign: "center"
                        }}
                    >
                        <FadeIn>
                            <Box
                                textAlign='center'>
                                <WhiteButton
                                    url="/faq"
                                    isXLarge={true}>
                                    よくある質問
                                </WhiteButton>
                            </Box>
                        </FadeIn>
                    </Container>
                </NormalGridContainer>
            </Container>

            <NormalGridContainer
                mdWidth={4}
                padding={10}>
                <FadeIn>
                    <Typography
                        variant="body1"
                        sx={{
                            paddingBottom: (theme) => theme.spacing(6),
                            color: (theme) => theme.palette.primary.main
                        }}
                    >
                        以下のフォームからお問い合わせください
                    </Typography>

                    <Stack spacing={3}>

                        <TextField
                            required
                            label="メールアドレス"
                            type="email"
                            {...register('email')}
                            error={"email" in errors}
                            helperText={errors.email?.message}
                            onChange={handleEmailChange} />

                        <TextField
                            required
                            label="お名前"
                            {...register('name')}
                            error={"name" in errors}
                            helperText={errors.name?.message}
                            onChange={handleNameChange} />

                        <TextField
                            required
                            label="件名"
                            {...register('title')}
                            error={"title" in errors}
                            helperText={errors.title?.message}
                            onChange={handleTitleChange} />

                        <TextField
                            required
                            label="お問合せ内容"
                            multiline
                            rows={Infinity}
                            {...register('body')}
                            error={"body" in errors}
                            helperText={errors.body?.message}
                            onChange={handleContentChange}
                        />

                        <MyButton
                            url="/"
                            onClick={handleSubmit(onSubmit)}
                            disabled = {isVisible}>
                            送信
                        </MyButton>
                    </Stack>
                </FadeIn>
            </NormalGridContainer>
        </>
    );
}

export default Contact;