import { Box, Container, SvgIcon, Typography } from "@mui/material";
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import NormalGridContainer from "components/common-components/container/NormalGridContainer";
import { MyButton } from "components/common-components/button/MyButton";
import { FadeIn } from "./FadeIn";


export const LinkToFAQ: React.FunctionComponent = () => {
    return <Container
        maxWidth={false}
        sx={{
            paddingBottom: (theme) => theme.spacing(4),
            paddingTop: (theme) => theme.spacing(4),
            backgroundColor: (theme) => theme.palette.secondary.light,
        }}
    >
        <NormalGridContainer
            mdWidth={10}
            xsWidth={11}
            padding={3}>

            <FadeIn>
                <SvgIcon
                    fontSize="large"
                    component={QuizOutlinedIcon}
                    color="primary" />

                <Typography variant="h4"
                    sx={{
                        paddingTop: (theme) => theme.spacing(1),
                        color: (theme) => theme.palette.primary.main
                    }}>
                    よくある質問
                </Typography>
                <Box
                    textAlign='center'
                    sx={{
                        paddingTop: (theme) => theme.spacing(4),
                        paddingBottom: (theme) => theme.spacing(4),
                    }}>
                    <MyButton url={"/faq"}>
                        詳しく見る
                    </MyButton>
                </Box>
            </FadeIn>
        </NormalGridContainer>
    </Container>
}