import { Container } from "@mui/material";
import { MyButton } from "components/common-components/button/MyButton";
import { ImageAndTitle } from "components/common-components/container/ImageAndTitle";
import NormalGridContainer from "components/common-components/container/NormalGridContainer";
import { FadeIn } from "components/common-components/FadeIn";
import { useEffect } from "react";
import smartphoneTroubleManColor from 'res/assets/common/smartphone_trouble_man_color.png';
import { setTitleAndDescription } from "utils/seoHelper";

function NotFound() {

    useEffect(() => {
        setTitleAndDescription("404","ページが見つかりません")
    })

    return (
        <Container
            maxWidth={false}
            sx={{
                paddingTop: (theme) => theme.spacing(12),
                paddingBottom: (theme) => theme.spacing(12),
            }}>
            <NormalGridContainer
                mdWidth={11}
                xsWidth={11}
                padding={8}>

                <FadeIn>

                    <Container
                        sx={{
                            paddingBottom: (theme) => theme.spacing(2)
                        }}>
                        <ImageAndTitle
                            variant="h1"
                            titleSize="1.3em!important"
                            title="404 ページが見つかりません"
                            height={141}
                            width={112}
                            src={smartphoneTroubleManColor}
                        />
                    </Container>
                    <MyButton
                        url="/"
                    >
                        ホームへ戻る
                    </MyButton>
                </FadeIn>
            </NormalGridContainer>
        </Container>
    );
}

export default NotFound;