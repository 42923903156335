import { useContext } from "react"
import { ThemeContext } from "res/style/theme"


type SectionSeparatorTriangleInterface = {
    color?: string
}

export const SectionSeparatorTriangle: React.FunctionComponent<SectionSeparatorTriangleInterface> = (props: SectionSeparatorTriangleInterface) => {

    // themeを使用可能にする
    const theme = useContext(ThemeContext)

    return (
        <div style={{
            textAlign: "center"
        }}>
            <svg width="40" height="40">
                <path
                    d="M0 0 L20 40 L40 0 Z"
                    fill={props.color ? props.color : theme.customPalette.white}
                ></path>
            </svg>
        </div>
    )
}

