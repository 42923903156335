import { Container, Typography } from "@mui/material"
import { Box } from "@mui/system";
import Breadcrumb from "components/common-components/Breadcrumbs"
import { MyButton } from "components/common-components/button/MyButton";
import NormalGridContainer from "components/common-components/container/NormalGridContainer"
import memoColor from 'res/assets/common/memo_color.png';

export const ContactConfirmed: React.FunctionComponent = () => {

    const pageName = "お問い合わせ完了"

    return <>
        <Breadcrumb pageName={pageName} />

        <Container
            maxWidth={false}
        >
            <NormalGridContainer
                mdWidth={5}
                xsWidth={11}
                padding={8}>

                <Typography
                    variant="h2"
                    color="primary"
                    sx={{
                        paddingBottom: (theme) => theme.spacing(5)
                    }}>
                    お問い合わせが完了しました
                </Typography>

                <Box
                    component="img"
                    sx={{
                        height: { xs: 80 },
                        width: { xs: 120 }
                    }}
                    alt="お問い合わせ完了"
                    src={memoColor}
                />

                <Typography
                    variant="body2"
                    sx={{
                        paddingTop: (theme) => theme.spacing(6),
                        paddingBottom: (theme) => theme.spacing(6),
                        color: (theme) => theme.palette.grey[800],
                        textAlign: "left"
                    }}>
                    お問い合わせいただきありがとうございました。<br />
                    お問い合わせを受け付けました。<br />
                    折り返し、担当者よりご連絡いたしますので、恐れ入りますが、しばらくお待ちください。<br />
                    なお、ご入力いただいたメールアドレス宛に受付完了メールを配信しております。完了メールが届かない場合、処理が正常に行われていない可能性があります。<br />
                    大変お手数ですが、再度お問い合わせの手続きをお願い致します。
                </Typography>
                <MyButton
                    url="/"
                >
                    ホームへ戻る
                </MyButton>

            </NormalGridContainer>
        </Container>
    </>
}