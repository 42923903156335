import { Container, Grid, Link } from "@mui/material";
import { headerLinks } from "const/headerLinks";

// React Component
const Footer: React.FunctionComponent = () => {
    return (
        <Container
            maxWidth={false}
            sx={{
                padding: (theme) => theme.spacing(3),
                background: (theme) => theme.customPalette.white,
                backgroundColor: (theme) => theme.palette.secondary.light
            }}
        >
            <Grid container>
                {headerLinks.map((item, index, array) => {
                    if (index == array.length - 1) {
                        return <>
                            <CreatFooterItems
                                url={item.url}
                                text={item.text} />
                            <CreatFooterItems
                                url="/privacyPolicy"
                                text="privacy policy" />
                        </>
                    } else {
                        return <CreatFooterItems
                            url={item.url}
                            text={item.text} />
                    }
                })}
            </Grid>
        </Container>
    );
}

const CreatFooterItems: React.FunctionComponent<{text: string,url: string}> = (prop) => {

    if(prop.text == "ENGLISH"){
        return <Link
            underline="none"
            target= "_blank"
            rel="noreferrer"
            href={prop.url}
            sx={{
                padding: (theme) => theme.spacing(2),
                textAlign: "center"
            }}>
                {prop.text}</Link>
    }

    return <Grid item
        xs={6}
        md={3}
        sx={{
            padding: (theme) => theme.spacing(2),
            textAlign: "center"
        }}>
        <Link
            underline="none"
            href={prop.url}>
            {prop.text}
        </Link>
    </Grid>
}

export default Footer;