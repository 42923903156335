import { linkInterface } from "types/linkInterface";

 // Headerをここで定義
 export const headerLinks: Array<linkInterface> = [
    { text: "ホーム", url: "/" }, 
    // { text: "ALT", url: "alt" },
    // { text: "NPO事業", url: "npo" },
    { text: "会社概要", url: "about" },
    { text: "お問い合わせ", url: "contact" },
    { text: "ENGLISH", url: "https://front-alt.com/" },
];
