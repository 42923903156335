import { createTheme, Theme } from "@mui/material";
import React from "react";

// Theme 
export const myTheme: Theme = createTheme({
    // mui デフォルトpalette
    palette: {
      primary: {
        // 赤
        main: '#00008b',
        dark: '#00008b',
        light: '#00008b',
      },
      secondary: {
        // 薄青
        main: '#87ceeb',
        dark: '#87ceeb',
        light: '#eff9ff66',
      },
    },
  
    typography: {
      h1: {
        fontWeight: 600,
        fontSize: '3rem',
        lineHeight: 1.7,
      },
      h2: {
        fontWeight: 600,
        fontSize: '2rem',
        lineHeight: 1.7, 
      },
      h3: {
        fontWeight: 500,
        fontSize: '1.9rem',
        lineHeight: 1.7, 
      },
      h4: {
        fontWeight: 400,
        fontSize: '1.8rem',
        lineHeight: 1.7, 
      },
      h5: {
        fontWeight: 400,
        fontSize: '1.7rem',
        lineHeight: 1.7, 
      },
      h6: {
        fontWeight: 300,
        fontSize: '1.5rem',
        lineHeight: 1.7, 
      },
      fontSize: 16
    },
  
    // 独自palette
    customPalette: {
      gray: "#EEEEEE",
      orange:"#d03c1b",
      lightOrange: "#f8ede1",
      superLightOrange: "#FCF9F6",
      lightBlue: "#eff9ff",
      superLightBlue: "#eff9ff66",
      white: "#ffffff",
      opacityBlack10:"#0000001A",
      opacityWhite50:"#ffffff80",
      opacityWhite10:"#ffffff1A",
    },
  });
  
  // 独自paletteを使えるようにする
  declare module '@mui/material/styles' {
    // ThemeにcustomPaletteを追加
    interface Theme {
      customPalette: {
        gray: string;
        orange: string;
        lightOrange: string;
        lightBlue?: string;
        superLightBlue?: string;
        superLightOrange: string;
        white: string;
        opacityBlack10:string;
        opacityWhite50:string;
        opacityWhite10:string;
      };
    }
    // ThemeOptionsにcustomPaletteを追加
    interface ThemeOptions {
      customPalette?: {
        gray?: string;
        orange?: string;
        lightOrange?: string;
        lightBlue?: string;
        superLightBlue?: string;
        superLightOrange?: string;
        white?: string;
        opacityBlack10:string;
        opacityWhite50:string;
        opacityWhite10:string;
      };
    }
  }

// FC内でもtheme使えるようにする
export const ThemeContext = React.createContext(myTheme);