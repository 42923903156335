import { Container, List, ListItem, Typography } from "@mui/material";
import Breadcrumb from "components/common-components/Breadcrumbs";
import { ImageAndTitle } from "components/common-components/container/ImageAndTitle";
import NormalGridContainer from "components/common-components/container/NormalGridContainer";
import { FadeIn } from "components/common-components/FadeIn";
import { LinkToContactUs } from "components/common-components/LinkToContactUs";
import { useEffect } from "react";
import memoColor from 'res/assets/common/memo_color.png';
import { setTitleAndDescription } from "utils/seoHelper";


const PrivacyPolicy: React.FunctionComponent = () => {

    const pageName = "Privacy policy"

    useEffect(() => {
        setTitleAndDescription(pageName)
    })

    const privacyPolicy = [
        "・生徒個人或いは、その家族の連絡先（住所、電話番号、メール等電子媒体含む）を所有しない。",
        "・講師個人の連絡先を、生徒個人或いは、その家族に伝えない。",
        "・その他、業務に関わりのない情報（銀行口座、プライベートな予定等）のやり取り、交換等を行わない。",
        "・個人情報保護の基本方針を定め、個人情報保護に努める。またそのことを周知徹底し、適正に運用する。",
        "・個人情報保護に関する法令及び規範を遵守し、個人情報の保護に努める。",
        "・予め定める利用目的の下で収集した個人情報は、安全な管理を徹底するとともに、その利用目的の範囲においてのみ利用する。",
        "・個人情報への不正アクセス、漏えい、滅失、毀損及び改ざんの予防に取り組む。",
        "・個人情報の保護の取組みについて、当学園は継続的かつ部分的或いは包括的に見直しを行い、改善を図る。"
    ]

    return (
        <>
            <Breadcrumb pageName={pageName} />

            <Container
                maxWidth={false}
                sx={{
                    paddingTop: (theme) => theme.spacing(12),
                    paddingBottom: (theme) => theme.spacing(6),
                }}
            >

                <NormalGridContainer
                    mdWidth={7}
                    xsWidth={11}
                    padding={3}>
                    <FadeIn>
                        <ImageAndTitle
                            title="個人情報の取り扱いについて"
                            height={100}
                            width={150}
                            src={memoColor}
                        />

                        <Typography
                            variant="body1"
                            sx={{
                                color: (theme) => theme.palette.grey[800],
                                paddingBottom: (theme) => theme.spacing(5),
                                textAlign: "left"
                            }}>
                            当法人では、ALTに対しても守秘義務に関して、地方公務員法第34条1項「職員は、職務上知り得た秘密を漏らしてはならない。<br />
                            その職を退いた後も、また、同様とする」に準じた義務を課しています。<br />
                            また業務上の実施事項として、以下を周知徹底しています。
                        </Typography>

                        <List>
                            {privacyPolicy.map((item, _) => (
                                <ListItem>
                                    <Typography
                                        sx={{
                                            color: (theme) => theme.palette.grey[600],
                                            textAlign: "left"
                                        }}
                                        variant="body1">
                                        {item}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </FadeIn>
                </NormalGridContainer>
            </Container>

            {/* お問合せ */}
            <LinkToContactUs />
        </>
    )
}

export default PrivacyPolicy;