import { companyDetails } from "const/companyDetails"

// useEffect内から呼び出すこと
// HeadにTitleとDescriptionを設定する関数
export const setTitleAndDescription = (title: string, description?: string) => {
    const pageTitle = title + " | " +  companyDetails.name
    document.title = pageTitle
    if(description != null){
        document.querySelector('meta[name="description"]')?.setAttribute("content", description)
    }else{
        document.querySelector('meta[name="description"]')?.setAttribute("content", companyDetails.name + "の" + title + "についてご案内いたします。")
    }    
}