import { Box, Grid, Typography } from "@mui/material"
import { Variant } from "@mui/material/styles/createTypography"

export const ImageAndTitleAndText: React.FunctionComponent<{
    titleLevel: Variant,
    title: string,
    body: string,
    src: string,
    height: number,
    width: number,
    isPictureOnright?: boolean
}> = (prop) => {

    var param2 = 1
    if (prop.isPictureOnright == false) {
        param2 = 2
    }

    return <Grid container
        alignItems="center"
        justifyContent="center"
        sx={{
            paddingBottom: (theme) => theme.spacing(5),
            paddingTop: (theme) => theme.spacing(5),
        }}
    >
        <Grid item
            sx={{
                textAlign: "center"
            }}
            order={{ xs: 1, md: param2 }}
            xs={10}
            md={4}>
            <Typography
                sx={{
                    textAlign: "center",
                    paddingBottom: (theme) => theme.spacing(3),
                    color: (theme) => theme.palette.primary.main
                }}
                variant={prop.titleLevel}
            >
                {prop.title}
            </Typography>
            <Typography
                sx={{
                    textAlign: "left",
                    paddingBottom: (theme) => theme.spacing(3),
                }}
                variant="body1"
            >
                {prop.body}
            </Typography>
        </Grid>

        <Grid item
            sx={{
                textAlign: "center",
            }}
            order={{ xs: param2, md: 1 }}
            xs={10}
            md={4}>
            <Box
                component="img"
                sx={{
                    height: { xs: prop.height / 1.5, md: prop.height },
                    width: { xs: prop.height / 1.5, md: prop.width, }
                }}
                alt={prop.titleLevel}
                src={prop.src}
            />
        </Grid>
    </Grid>
}
